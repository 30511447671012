var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"mb-2"},[_vm._m(1),_c('div',{class:{ 'border-error': _vm.vm.walletAddressError, 'border-trans': !_vm.vm.walletAddressError }},[_c('v-text-field',{staticClass:"rounded-0 algo-grey darken-1 text-input text-custom--white",attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"solo":"","dense":"","flat":"","hide-details":"","value":_vm.wallet.account,"disabled":""}})],1),_c('v-sheet',{staticClass:"transparent mt-2",attrs:{"height":"24"}})],1),_c('div',{staticClass:"mb-2"},[_vm._m(2),_c('div',{class:{ 'border-error': _vm.vm.emailAddressError, 'border-trans': !_vm.vm.emailAddressError }},[_c('v-text-field',{staticClass:"rounded-0 algo-grey darken-1 text-input",class:{
            'text-error': _vm.vm.emailAddressError,
          },attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"solo":"","dense":"","flat":"","hide-details":"","value":_vm.vm.emailAddress},on:{"input":_vm.vm.onEmailAddressChange}})],1),_c('v-sheet',{staticClass:"transparent text-end mt-2 error--text",attrs:{"height":"24"}},[_vm._v(_vm._s(_vm.vm.emailAddressError ? 'Wrong address' : ''))])],1),_c('div',{staticClass:"mb-8"},[_vm._m(3),_c('div',{class:{ 'border-error': _vm.vm.usernameError, 'border-trans': !_vm.vm.usernameError }},[_c('v-text-field',{staticClass:"rounded-0 algo-grey darken-1 text-input",class:{
            'text-error': _vm.vm.usernameError,
          },attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"solo":"","dense":"","flat":"","hide-details":"","value":_vm.vm.username},on:{"input":_vm.vm.onUsernameChange}})],1),_c('v-sheet',{staticClass:"transparent text-end mt-2 error--text",attrs:{"height":"24"}},[_vm._v(_vm._s(_vm.vm.agreePolicyError ? 'Wrong address' : ''))])],1),_c('div',{class:{
        'border-active': _vm.vm.agreePolicy && !_vm.vm.agreeError,
        'border-trans': !_vm.vm.agreePolicy && !_vm.vm.agreeError,
        'border-error': _vm.vm.agreePolicyError,
      }},[_c('v-sheet',{attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"color":"d-flex align-center pl-5 algo-grey darken-1"},on:{"click":_vm.vm.onAcceptTermChange}},[_c('div',{staticStyle:{"max-width":"20px"}},[(_vm.vm.agreePolicyError)?_c('v-img',{attrs:{"src":require('@/assets/icons/checkbox-error.svg')}}):(_vm.vm.agreePolicy)?_c('v-img',{attrs:{"src":require('@/assets/icons/checkbox-active.svg')}}):_c('v-img',{attrs:{"src":require('@/assets/icons/checkbox-none.svg')}})],1),_c('div',{staticClass:"ml-3 agree-term",class:{ 'primary--text': _vm.vm.agreePolicy && !_vm.vm.agreePolicyError, 'error--text': _vm.vm.agreePolicyError },staticStyle:{"cursor":"pointer"}},[_vm._v(" I agree to the "),_c('router-link',{staticStyle:{"color":"inherit"},attrs:{"to":{ path: '/home' }}},[_vm._v("terms and condition")])],1)])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-md-row primary--text align-baseline mb-7 mb-md-10"},[_c('div',{staticClass:"header-hero audiowide-font mb-md-0 mr-md-3"},[_vm._v("Registration")]),_c('div',{staticClass:"header-sub"},[_vm._v("Only one account per person")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-md-row justify-md-space-between"},[_c('div',{staticClass:"label font-weight-bold mb-md-2"},[_vm._v("Wallet address")]),_c('div',{staticClass:"sub-label algo-grey--text text--lighten-1 mb-2 mb-md-0"},[_vm._v(" We will use this wallet for login purpose ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"label font-weight-bold mb-md-2"},[_vm._v("Email address")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-md-row justify-md-space-between"},[_c('div',{staticClass:"label font-weight-bold mb-md-2"},[_vm._v("User name")]),_c('div',{staticClass:"sub-label algo-grey--text text--lighten-1 mb-2 mb-md-0"},[_vm._v("This could be presented publicly")])])}]

export { render, staticRenderFns }