
















































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { RegisterViewModel } from '../viewmodels/register-viewmodel'

@Observer
@Component
export default class RegisterForm extends Vue {
  @Inject() vm!: RegisterViewModel
  wallet = walletStore
}
